import React from "react";
import "styles/pages/page-insight-solutions.scss";
import Layout from "components/Layout";
import TitleWithLine from "../components/TitleWithLine";
import SubpageHeader from "../components/SubpageHeader";
import Seo from "components/Seo";
import WhiteTile from "../components/WhiteTile";

const InsightSolutions = ({ pageContext }) => {
  const is = pageContext.pageContent;

  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title="Insight Solutions for <br />
        Insurance Intermediaries"
        image={require("assets/images/forecasting-bg.jpg").default}
      />
      <section className="section-clients">
        <div className="container">
          <div className="section-clients__desc text-center">
            {is.prodinityHasDevelopedTitle}
          </div>
          <div className="row">
            {is.prodinityHasDevelopedTiles.map((item, index) => {
              return (
                <div className="col-xl-2 col-md-4" key={index}>
                  <WhiteTile img={item.logo?.sourceUrl}>
                    {item.tileText}
                  </WhiteTile>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="section-examples">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="section-examples__inner">
                <TitleWithLine variantY="bottom">
                  {is.insightExamplesTitle}
                </TitleWithLine>
                <p className="section-examples__desc section-examples__desc--red">
                  {is.insightExamplesSubtitle}
                </p>
                <div
                  className="section-examples__desc"
                  dangerouslySetInnerHTML={{ __html: is.insightExamplesText1 }}
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <img
                src={is.insightExamplesImage1?.sourceUrl}
                alt=""
                className="img-fluid section-examples__img"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-notes">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={is.insightExamplesImage2?.sourceUrl}
                alt=""
                className="img-fluid section-notes__img"
              />
            </div>
            <div className="col-lg-5">
              <div className="section-notes__inner">
                <div
                  className="section-examples__desc"
                  dangerouslySetInnerHTML={{ __html: is.insightExamplesText2 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-automation">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-automation__inner">
                <div
                  className="section-examples__desc"
                  dangerouslySetInnerHTML={{ __html: is.insightExamplesText3 }}
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <img
                src={is.insightExamplesImage3?.sourceUrl}
                alt=""
                className="img-fluid section-automation__img"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-notes">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={is.insightExamplesImage4?.sourceUrl}
                alt=""
                className="img-fluid section-notes__img"
              />
            </div>
            <div className="col-lg-5">
              <div className="section-notes__inner">
                <div
                  className="section-examples__desc"
                  dangerouslySetInnerHTML={{ __html: is.insightExamplesText4 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-automation">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-automation__inner">
                <div
                  className="section-examples__desc"
                  dangerouslySetInnerHTML={{ __html: is.insightExamplesText5 }}
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <img
                src={is.insightExamplesImage5?.sourceUrl}
                alt=""
                className="img-fluid section-automation__img"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default InsightSolutions;
